import React from "react";
import Layout from "../components/organisms/layout/layout";
import LibraryComponents from "../components/templates/library-components/libraryComponents";
import { ReferenciaContenido } from "../interfaces/ReferenciaContenido";
import SEO from "../shared/seo";

const TemplateEspecial = (props: any) => {

    const pageSlug = props.location.pathname

    return (
        <Layout hideHeaderDesktop={false} hideHeaderMobile isNotRoot hideSidebar headboard={props.pageContext.referenciaCabecera} >
            <SEO title={props.pageContext.metaTitulo || "Comfama - Caja de compensación familiar"} 
                description={ props.pageContext.metaDescripcion && props.pageContext.metaDescripcion.json.content[0].content[0].value }
                noIndex={props.pageContext.noIndex}
                image={props.pageContext.referenciaCabecera.imagenHeader}
                slug={pageSlug}
                fbId={process.env.FACEBOOK_APP_ID_HOME}
            />
            {
                props.pageContext.referenciaContenido
                ? props.pageContext.referenciaContenido.map(
                    ( value: ReferenciaContenido, index: number) => (
                        <LibraryComponents
                            key={index}
                            content={value}
                            categoryName={props.pageContext.nombreCategoria}
                        />
                    )
                )
                : null
            }
        </Layout>
    )
}

export default TemplateEspecial
